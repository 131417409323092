const state = {
    isAuth: true,
};
const getters = {};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
