const state = {
    userName: "lawal"
}
const getters = {};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}